import React , {Component} from 'react';
import {checkPermission} from "../../utils/methods";
import {LandingFooterPanelV2, Layout, NavigationBarV2, EventsPage} from "../../components";
import PropTypes from "prop-types";
class EventsNew extends Component{
  render() {
    const { history } = this.props;
    const authToken = (checkPermission() && localStorage && localStorage.getItem('AUTH_TOKEN')) ? true : false;
    return (
      <Layout>
        <NavigationBarV2
          history={history}
          showInternalLinks={false}
          isUser={authToken}
        />
        <EventsPage  history={history}/>
        <LandingFooterPanelV2 isUser={authToken} />
      </Layout>
    );
  }
}

EventsNew.propTypes = {
  history: PropTypes.object
}
export default EventsNew;