/* eslint-disable no-undef */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import {
  MainDashboardV3,
  Layout,
  NavigationBarV2,
  LandingFooterPanelV2,
} from "../../components";
import { checkPermission } from "../../utils/methods";

class HomePage extends Component {
  componentDidMount() {
    
    window.pipedriveLeadboosterConfig = {
      base: "leadbooster-chat.pipedrive.com",
      companyId: 7880254,
      playbookUuid: "f19dd968-7246-480d-8be3-1200888121a1",
      version: 2,
    };
    (function () {
      const w = window;
      if (w.LeadBooster) {
        // console.warn("LeadBooster already exists");
      } else {
        if (window.location.pathname == "/") {
          w.LeadBooster = {
            q: [],
            on: function (n, h) {
              this.q.push({ t: "o", n: n, h: h });
            },
            trigger: function (n) {
              this.q.push({ t: "t", n: n });
            },
          };
        }
      }
    })();
  }
  render() {

    // const leadboosterContainer = document.getElementById("LeadboosterContainer");
    // if (leadboosterContainer) {
    //   leadboosterContainer.setAttribute("style", "margin-bottom: 50px !important");
    // }

    const { history } = this.props;
    const authToken =
      checkPermission() && localStorage && localStorage.getItem("AUTH_TOKEN")
        ? true
        : false;
    return (
      <Layout>
        <NavigationBarV2
          history={history}
          showInternalLinks={false}
          isUser={authToken}
        />
        <MainDashboardV3 history={history} />
        <LandingFooterPanelV2 isUser={authToken} />
      </Layout>
    );
  }
}

HomePage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(HomePage);
