/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  // EditEvent,
  // CreateEvent,
  MainEvents,
  EventDetails,
  // Waiting,
  AdminRoute,
  OnDemandDetailsMain,
  OnDemandVideoDetailMain,
  // CreateOndemandMain,
  // EditOndemandMain,
  CompletedEvents,
  EventCreate,
  LiveEventsMain,
  ScheduleEventsMain
} from "../../components";
import {
  createEvent,
  getPlacesAction,
  getEvents,
  getEventDetailsById,
  getCommentsOnEvent,
  addCommentOnEvent,
  addCommentReplyOnEvent,
  getUserProfileImage,
  attendEvent,
  getEventAttendees,
  getUserRole,
  getPastEvents,
  getOndemandEventDetailsById,
  createOndemand,
  getOndemandCategory,
  fetchCompanyEvents,
  fetchWolibaEvents,
  attendEventV2,
  attendEventV3,
  attendEventV5,
  attendEventV6
} from "../../redux/actions";
import { getPermissionStatus } from "../../utils/methods";
import EditEvent from '../../components/EditEvent/editEvent';
import CreateOndemandMain from '../../components/MainEvents/CreateOndemandClass/createOndemandClass';
// import EditOndemandMain from '../../components/MainEvents/EditOndemandClass/editOndemandClass';
import SkeletonLoder from "../../components/common/skeletonLoder";
import EventCoachCreate from "../../components/CompanyEvents/EventCoachCreate";
import CoachMain from '../../components/EventDetails/CoachDetails/index';
import {attendEventLimitUser} from '../../redux/actions/eventActions'

class Events extends Component {
  componentDidMount() {
    this.props.fetchUserRole();
    this.props.fetchEvents(1);
    if (!this.props.profileImage) this.props.fetchUserProfileImage();
    this.props.fetchOndemandCategory();
    this.props.fetchWolibaEvents();
    this.props.fetchCompanyEvents();
  }

  fetchPastEvents = () => {
    this.props.getPastEvents(1);
  };

  loadMorePastEvents = (pageNumber) => {
    const { getPastEvents } = this.props;
    getPastEvents(pageNumber);
  };

  render() {
    const {
      createEvent,
      places,
      getPlacesAction,
      history,
      events,
      pastEvents,
      networkError,
      fetchEventDetailsById,
      fetchCommentsOnEvent,
      postCommentOnEvent,
      postReplyOnComment,
      replySuccess,
      profileImage,
      totalCount,
      attendEvent,
      getEventAttendees,
      isLoading,
      role,
      userPermissions,
      fetchOndemandEventDetailsById,
      createOndemand,
      fetchOndemandCategory,
      wolibaEvents,
      companyEvents,
      attendEventV2,
      attendEventV3,
      attendEventV5,
      attendEventV6,
      attendEventLimitUser
    } = this.props;
    if (
      _.isNull(events) ||
      _.isNull(pastEvents) ||
      _.isNull(totalCount) ||
      (!role && !networkError)
    ) {
      return <div style={{width:'1246px', margin:'auto', padding:'25px 0px'}}>
        <SkeletonLoder width={"100%"} height={"60px"}/>
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <SkeletonLoder width={"300px"} height={"25px"} style={{margin:"25px 0px"}}/>
          <SkeletonLoder width={"100px"} height={"25px"} style={{margin:"25px 0px"}}/>
        </div>
        <SkeletonLoder width={"100%"} height={"83px"} style={{marginBottom:"25px"}}/>
        <div style={{width:'100%', display:'flex', gap:'25px', flexWrap:'wrap'}}>
          <SkeletonLoder width={"398px"} height={"320px"}/>
          <SkeletonLoder width={"398px"} height={"320px"}/>
          <SkeletonLoder width={"398px"} height={"320px"}/>
          <SkeletonLoder width={"398px"} height={"320px"}/>
          <SkeletonLoder width={"398px"} height={"320px"}/>
          <SkeletonLoder width={"398px"} height={"320px"}/>
        </div>
      </div>;
    }
    const createEventPermission = getPermissionStatus(
      "Create event",
      userPermissions
    );
    const updateEventPermission = getPermissionStatus(
      "Update event",
      userPermissions
    );
    const addOrRemoveUserPermission = getPermissionStatus(
      "Add or Remove user",
      userPermissions
    );
    const printEventCsvPermission = getPermissionStatus(
      "Print event CSV",
      userPermissions
    );
    return (
      <Switch>
        <AdminRoute
          path="/events/create"
          component={EventCreate}
          createEvent={createEvent}
          getPlacesAction={getPlacesAction}
          places={places}
          history={history}
          parentRoute="/events"
          allowedRoles={createEventPermission}
          userPermissions={userPermissions}
        />
        {/* <AdminRoute
          path="/company/events/create"
          component={EventCreate}
          createEvent={createEvent}
          getPlacesAction={getPlacesAction}
          places={places}
          history={history}
          parentRoute="/events"
          allowedRoles={createEventPermission}
          userPermissions={userPermissions}
        /> */}
        
        <AdminRoute
          path="/events/create-ondemand"
          component={CreateOndemandMain}
          createOndemand={createOndemand}
          history={history}
          parentRoute="/events"
          allowedRoles={createEventPermission}
          userPermissions={userPermissions}
          fetchOndemandCategory={fetchOndemandCategory}
        />
        <AdminRoute
          path="/events/edit-ondemand/:id"
          history={history}
          component={CreateOndemandMain}
          parentRoute="/events"
          allowedRoles={updateEventPermission}
          userPermissions={userPermissions}
          fetchOndemandEventDetailsById={fetchOndemandEventDetailsById}
          fetchOndemandCategory={fetchOndemandCategory}
          isEditCategory={1}
        />
        {/* <AdminRoute
          path="/events/edit-ondemand/:id"
          history={history}
          component={EditOndemandMain}
          parentRoute="/events"
          allowedRoles={updateEventPermission}
          userPermissions={userPermissions}
          fetchOndemandEventDetailsById={fetchOndemandEventDetailsById}
          fetchOndemandCategory={fetchOndemandCategory}
        /> */}
        <Route
          path="/events/ondemand/:id"
          render={() => (
            <OnDemandDetailsMain
              history={history}
              fetchOndemandEventDetailsById={fetchOndemandEventDetailsById}
              role={role}
              isAdmin={createEventPermission}
            />
          )}
        />
        <Route
          path="/events/on-demand/:category_name/:id"
          render={() => (
            <OnDemandVideoDetailMain
              fetchOndemandEventDetailsById={fetchOndemandEventDetailsById}
              history={history}
              allowedRoles={role}
              userPermissions={userPermissions}
              role={role}
              isAdmin={createEventPermission}
            />
          )}
        />
        <AdminRoute
          path="/events/:id/edit"
          component={EditEvent}
          createEvent={createEvent}
          getPlacesAction={getPlacesAction}
          places={places}
          history={history}
          isLoading={isLoading}
          parentRoute="/events"
          allowedRoles={updateEventPermission}
          userPermissions={userPermissions}
        />
        <Route
          path="/events/completed"
          render={() => (
            <CompletedEvents
              history={history}
              attendEvent={attendEvent}
              pastEvents={pastEvents}
              isLoading={isLoading}
              loadMoreEvents={(pageNumber) => this.loadMorePastEvents(pageNumber)}
              totalCount={totalCount}
              role={role}
              isAdmin={createEventPermission}
              fetchPastEvents={this.fetchPastEvents}
            />
          )}
        />
        <Route
          path="/events/live"
          render={() => (
            <LiveEventsMain
              history={history}
              totalCount={totalCount}
              attendEvent={attendEventV3}
              role={role}
            />
          )}
        />
        <Route
          path="/events/schedule"
          render={() => (
            <ScheduleEventsMain
              history={history}
              totalCount={totalCount}
              attendEvent={attendEventV3}
              role={role}
            />
          )}
        />

        <AdminRoute
          path="/events/create-coach"
          component={EventCoachCreate}
          history={history}
          parentRoute="/events"
          allowedRoles={createEventPermission}
          userPermissions={userPermissions}
        />
        <Route
          path="/events/coach/:id"
          render={() => (
            <CoachMain
              history={history}
              role={role}
              attendEvent={attendEventV5}
              attendEventV2={attendEventV6}
            />
          )}
        />
        <Route
          path="/events/:id"
          render={() => (
            <EventDetails
              history={history}
              fetchEventDetailsById={fetchEventDetailsById}
              networkError={networkError}
              fetchCommentsOnEvent={fetchCommentsOnEvent}
              postCommentOnEvent={postCommentOnEvent}
              postReplyOnComment={postReplyOnComment}
              replySuccess={replySuccess}
              profileImage={profileImage}
              attendEvent={attendEvent}
              getEventAttendees={getEventAttendees}
              isAdmin={updateEventPermission}
              addOrRemoveUserPermission={addOrRemoveUserPermission}
              printEventCsvPermission={printEventCsvPermission}
              role={role}
              attendEventLimitUser={attendEventLimitUser}
            />
          )}
        />
        <Route
          render={() => (
            <MainEvents
              isAdmin={createEventPermission}
              history={history}
              eventResults={events}
              pastEvents={pastEvents}
              loadMoreEvents={(pageNumber) => this.loadMoreEvents(pageNumber)}
              totalCount={totalCount}
              attendEvent={attendEventV2}
              role={role}
              wolibaEvents={wolibaEvents}
              companyEvents={companyEvents}
              fetchEvents = {this.props.fetchEvents}
              fetchWolibaEvents={this.props.fetchWolibaEvents}
              fetchCompanyEvents={this.props.fetchCompanyEvents}
              attendEventLimitUser={attendEventLimitUser}
            />
          )}
        />
      </Switch>
    );
  }
}

Events.propTypes = {
  createEvent: PropTypes.func.isRequired,
  getPlacesAction: PropTypes.func.isRequired,
  places: PropTypes.array,
  isLoading: PropTypes.bool,
  history: PropTypes.object.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  getPastEvents: PropTypes.func.isRequired,
  events: PropTypes.array,
  pastEvents: PropTypes.array,
  networkError: PropTypes.string,
  fetchEventDetailsById: PropTypes.func.isRequired,
  fetchCommentsOnEvent: PropTypes.func.isRequired,
  postCommentOnEvent: PropTypes.func.isRequired,
  postReplyOnComment: PropTypes.func.isRequired,
  replySuccess: PropTypes.string,
  fetchUserProfileImage: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  totalCount: PropTypes.number,
  attendEvent: PropTypes.func.isRequired,
  getEventAttendees: PropTypes.func.isRequired,
  fetchUserRole: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  userPermissions: PropTypes.array,
  fetchOndemandEventDetailsById: PropTypes.func.isRequired,
  createOndemand: PropTypes.func.isRequired,
  fetchOndemandCategory: PropTypes.func,
  fetchWolibaEvents: PropTypes.func,
  fetchCompanyEvents: PropTypes.func,
  companyEvents: PropTypes.array,
  wolibaEvents: PropTypes.array,
  attendEventV2: PropTypes.func.isRequired,
  attendEventV3: PropTypes.func.isRequired,
  attendEventV5: PropTypes.func.isRequired,
  attendEventV6: PropTypes.func.isRequired,
  attendEventLimitUser: PropTypes.func
};

const mapStateToProps = (state) => ({
  eventCreated: state.events.eventCreated,
  places: state.events.places,
  isLoading: state.events.isLoading,
  events: state.events.events,
  pastEvents: state.events.pastEvents,
  networkError: state.events.networkError,
  replySuccess: state.events.replySuccess,
  profileImage: state.profileData.profileImage,
  totalCount: state.events.totalCount,
  role: state.auth.role,
  userPermissions: state.profileData.userPermissions,
  wolibaEvents: state.events.wolibaEvents,
  companyEvents: state.events.companyEvents
});

const mapDispatchToProps = (dispatch) => ({
  createEvent: (eventData) => dispatch(createEvent(eventData)),
  getPlacesAction: (location) => dispatch(getPlacesAction(location)),
  fetchEvents: (pageNumber) => dispatch(getEvents(pageNumber)),
  fetchEventDetailsById: (id) => dispatch(getEventDetailsById(id)),
  fetchCommentsOnEvent: (id) => dispatch(getCommentsOnEvent(id)),
  postCommentOnEvent: (data) => dispatch(addCommentOnEvent(data)),
  postReplyOnComment: (commentId, reply, eventId) => dispatch(addCommentReplyOnEvent(commentId, reply, eventId)),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
  attendEvent: (eventId, isAttending, updationList) => dispatch(attendEvent(eventId, isAttending, updationList)),
  getEventAttendees: (eventId) => dispatch(getEventAttendees(eventId)),
  fetchUserRole: () => dispatch(getUserRole()),
  getPastEvents: (pageNumber) => dispatch(getPastEvents(pageNumber)),
  fetchOndemandEventDetailsById: (id) => dispatch(getOndemandEventDetailsById(id)),
  createOndemand: (eventData) => dispatch(createOndemand(eventData)),
  fetchOndemandCategory: () => dispatch(getOndemandCategory()),
  fetchCompanyEvents: () => dispatch(fetchCompanyEvents()),
  fetchWolibaEvents: () => dispatch(fetchWolibaEvents()),
  attendEventV2: (eventId, isAttending, addCalendar) => dispatch(attendEventV2(eventId, isAttending, addCalendar)),
  attendEventV3: (eventId, isAttending, addCalendar, pageNo) => dispatch(attendEventV3(eventId, isAttending, addCalendar, pageNo)),
  attendEventV5: (eventId, isAttending, addCalendar, callBack) => dispatch(attendEventV5(eventId, isAttending, addCalendar, callBack)),
  attendEventV6: (eventId, isAttending, callBack) => dispatch(attendEventV6(eventId, isAttending, callBack)),
  attendEventLimitUser: (eventId, isAttending, callback) => dispatch(attendEventLimitUser(eventId, isAttending, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);
